import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { Lens } from "monocle-ts";

import type { InvestorFocusU } from "@scripts/generated/domaintables/investorFocuses";
import type { InvestorTypeU } from "@scripts/generated/domaintables/investorTypes";
import type { SectorU } from "@scripts/generated/domaintables/sectors";
import type { InstitutionalInvestorInfo, InstitutionalInvestorInfoPostC, institutionalInvestorInfoPostC } from "@scripts/generated/models/institutionalInvestorInfo";
import type { IndicatorOption, Label } from "@scripts/react/components/form/CustomIndicator";
import type { UnsafeFormData } from "@scripts/react/form/form";
import { emptyFormState, formDataLens } from "@scripts/react/form/form";

export type InvProFormData = UnsafeFormData<InstitutionalInvestorInfoPostC>;
type InvProPostKeys = keyof typeof institutionalInvestorInfoPostC["props"];

export const invProDL = formDataLens<InstitutionalInvestorInfoPostC>();

export const invProLens = Lens.fromProp<InvProFormData>();

const invPrDataL = (k: InvProPostKeys) => invProDL.compose(invProLens(k));

export const initializeState = (instInv: InstitutionalInvestorInfo) => {
  return pipe(
    emptyFormState<InstitutionalInvestorInfoPostC>(),
    pipe(instInv.focuses, RA.map(_ => _.id), invPrDataL("focusIds").set),
    pipe(instInv.sectors, RA.map(_ => _.id), invPrDataL("sectorIds").set),
    pipe(instInv.investorTypeId, O.map(_ => _.id), invPrDataL("investorTypeId").set),
  );
};

export const fociToIndicatorOptions = (
  foci: ReadonlyArray<InvestorFocusU | SectorU | InvestorTypeU>,
): ReadonlyArray<IndicatorOption<number, Label>> => pipe(
  foci,
  RA.map((f: SectorU | InvestorFocusU | InvestorTypeU) => ({ label: O.some(f.name), value: f.id }))
);

export const focusCopy = "Tell us a little more about your investment focus to get the most relevant information.";
