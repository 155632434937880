import { O, pipe } from "@scripts/fp-ts";
import { allInvestorType } from "@scripts/generated/domaintables/investorTypes";
import type { ContactPost } from "@scripts/generated/models/contact";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { LoginPageData, LoginPostC } from "@scripts/generated/models/login";
import type { SignupPostC } from "@scripts/generated/models/signup";
import type { SelectOption } from "@scripts/react/components/form/Select";
import { emptyFormState, formDataLens, formLens } from "@scripts/react/form/form";
import { openInSameTab } from "@scripts/routes/router";

export const loginFormDL = formDataLens<LoginPostC>();
export const loginFormL = formLens<LoginPostC>();

export const makeLoginFormState = (data: LoginPageData) => pipe(
  emptyFormState<LoginPostC>(),
  loginFormDL.compose(loginFormL("redirect")).set(data.redirect)
);

export const signUpFormDL = formDataLens<SignupPostC>();
export const signupFormL = formLens<SignupPostC>();

export const makeCreateAccountFormState = (data: LoginPageData) => pipe(
  emptyFormState<SignupPostC>(),
  signUpFormDL.compose(signupFormL("redirect")).set(data.redirect),
  signUpFormDL.compose(signupFormL("issuerId")).set(O.map((issuer: Issuer) => issuer.id)(data.issuer)),
  state => O.fold(
    () => state,
    (contact: ContactPost) => pipe(
      state,
      signUpFormDL.compose(signupFormL("email")).set(contact.email),
      signUpFormDL.compose(signupFormL("firstName")).set(contact.firstName),
      signUpFormDL.compose(signupFormL("lastName")).set(contact.lastName),
      signUpFormDL.compose(signupFormL("company")).set(O.toUndefined(contact.company)),
    ),
  )(data.contactSubmission)
);

export const loginSignUpRedirect = openInSameTab;

export const investorTypeOptions = allInvestorType.map((_): SelectOption<number> => ({ value: _.id, label: _.name }));
