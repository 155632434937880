import type { BLConfigWithLog } from "@scripts/bondlink";
import type { PagesRouteWithRender } from "@scripts/routes/routing/ssr/pages";

import { reactHydrate } from "@scripts-ssr/client";
import type { PageData } from "@scripts-ssr/syntax/pageData";

import { PageLoader } from "../components/PageLoader";
import type { PagesInitialState } from "../state/store";
import { createPagesStore } from "../state/store";


export const hydratePage = (config: BLConfigWithLog) => <SessionA, SessionO, StoreA extends PagesInitialState, StoreO, PropsA, PropsO>(
  pageWithRender: PagesRouteWithRender<PropsA, PropsO>
) => (
  pageData: PageData<SessionA, SessionO, StoreA, StoreO, PropsA, PropsO>
) => {
  const store = createPagesStore(config)(pageData.initialState);

  reactHydrate(
    <PageLoader
      config={config}
      pageTitle={pageData.title}
      render={() => pageWithRender.render(pageData.props)}
      store={store}
    />
  );
};
