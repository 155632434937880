import type { Dispatch, ReactElement, SetStateAction } from "react";
import { constVoid, pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";

import { b, E } from "@scripts/fp-ts";
import { allInvestorFocus } from "@scripts/generated/domaintables/investorFocuses";
import { allInvestorType } from "@scripts/generated/domaintables/investorTypes";
import type { InstitutionalInvestorInfoC, InstitutionalInvestorInfoPostC } from "@scripts/generated/models/institutionalInvestorInfo";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { ButtonsContainer, ButtonSubmit } from "@scripts/react/components/Button";
import { falseOrEmpty, mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { CustomIndicator, CustomIndicatorArray, CustomIndicatorRaw } from "@scripts/react/components/form/CustomIndicator";
import { Form } from "@scripts/react/components/form/Form";
import { DividerSection } from "@scripts/react/components/layout/Section";
import { booleanC, numberC, optionC } from "@scripts/react/form/codecs";
import type { FormState, FormSuccessAction, UnsafeDataLens, UnsafeFormProp } from "@scripts/react/form/form";
import { getValue, updateAndSetState, useUpdateAndSetStateViaCodecTransition } from "@scripts/react/form/form";
import type { SetState } from "@scripts/react/syntax/react";
import { isInstitutionalPersona } from "@scripts/syntax/persona";
import { allSectorsAlphabetical } from "@scripts/syntax/sectors";
import type { DeepPartialWithOptions } from "@scripts/types";
import { uniqueAriaId } from "@scripts/util/labelOrAriaLabel";

import { fociToIndicatorOptions, focusCopy, invProLens } from "./investorInstitutionSyntax";

type InvestorInstitutionFormProps = {
  personaId: O.Option<number>;
  showInvestorType: boolean;
  onSuccess: FormSuccessAction<InstitutionalInvestorInfoPostC, InstitutionalInvestorInfoC>;
  dismissButton: O.Option<ReactElement>;
  state: FormState<InstitutionalInvestorInfoPostC>;
  setState: SetState<FormState<InstitutionalInvestorInfoPostC>>;
};

const NoFocusIndicator = (props: {
  state: FormState<InstitutionalInvestorInfoPostC>;
  setState: Dispatch<SetStateAction<FormState<InstitutionalInvestorInfoPostC>>>;
  noFocusLens: UnsafeDataLens<InstitutionalInvestorInfoPostC, UnsafeFormProp<boolean>>;
  extraFieldLens: UnsafeDataLens<InstitutionalInvestorInfoPostC, DeepPartialWithOptions<ReadonlyArray<number>>>;
  focusType: string;
}) => {
  const [localFocus, setFocus] = useUpdateAndSetStateViaCodecTransition(getValue(props.state, props.noFocusLens), b.Eq);
  return <CustomIndicatorRaw<number>
    name={`no-${props.focusType}-focus`}
    unselectedValue={O.none}
    options={[{ label: O.some(`I don’t have a ${props.focusType} focus`), value: 0 }]}
    value={pipe(localFocus, O.map(_ => _ ? [0] : []))}
    type={"checkbox"}
    onChange={val => {
      if (val.length > 0) {
        updateAndSetState(props.setState, props.extraFieldLens)([]);
        setFocus(props.setState, props.noFocusLens, booleanC)(true);
      } else {
        setFocus(props.setState, props.noFocusLens, booleanC)(false);
      }
    }}
    errors={E.right([])}
  />;
};

export const InvestorInstitutionForm = (props: InvestorInstitutionFormProps) => {
  const invTypeHeadline = "What type of institutional investor are you?";
  const isInstitutional = isInstitutionalPersona(props.personaId);
  const focusHeadline = "What is your product focus?";
  const sectorHeadline = "Which sectors are your focus?";

  const invTypeAriaUId = uniqueAriaId(invTypeHeadline);
  const focusAriaUId = uniqueAriaId(focusHeadline);
  const sectorAriaUId = uniqueAriaId(sectorHeadline);

  return <Form
    url={V2Router.investorPortalUserControllerPostInstitutionalInvestorInfo()}
    state={props.state}
    setState={props.setState}
    onSuccess={props.onSuccess}
    onFailure={O.none}
    headers={O.none}
  >
    {trueOrEmpty(<DividerSection title={O.some(invTypeHeadline)}>
      <CustomIndicator
        state={props.state}
        setState={props.setState}
        lens={invProLens("investorTypeId")}
        codec={optionC(numberC)}
        name="investor type"
        type="radio"
        options={fociToIndicatorOptions(allInvestorType)}
        unselectedValue={O.none}
        analyticsScope={O.none}
        ariaUId={O.some(invTypeAriaUId)}
      />
    </DividerSection>)(props.showInvestorType && isInstitutional)}
    <DividerSection title={O.none}>
      {focusCopy}
    </DividerSection>
    <DividerSection
      suppressDivider="xs"
      title={O.some(focusHeadline)}
      klasses="institution-section"
    >
      <CustomIndicatorArray
        groupLabel="Product Focus"
        state={props.state}
        setState={props.setState}
        lens={invProLens("focusIds")}
        codec={numberC}
        name="product focus"
        type="checkbox"
        options={fociToIndicatorOptions(allInvestorFocus)}
        unselectedValue={O.none}
        analyticsScope={O.none}
        ariaUId={O.some(focusAriaUId)}
        disabled={pipe(props.state.data.noFocus, O.fromNullable, O.getOrElse(() => false))}
      />
      {falseOrEmpty(<NoFocusIndicator
        state={props.state}
        setState={props.setState}
        focusType="product"
        noFocusLens={invProLens("noFocus")}
        extraFieldLens={invProLens("focusIds")}
      />)(isInstitutional)}
    </DividerSection>
    <DividerSection
      suppressDivider={"xs"}
      title={O.some(sectorHeadline)}
      klasses="institution-section"
    >
      <CustomIndicatorArray
        groupLabel="Sector Focus"
        state={props.state}
        setState={props.setState}
        lens={invProLens("sectorIds")}
        codec={numberC}
        name="sector focus"
        type="checkbox"
        options={fociToIndicatorOptions(allSectorsAlphabetical)}
        unselectedValue={O.none}
        analyticsScope={O.none}
        ariaUId={O.some(sectorAriaUId)}
        disabled={pipe(props.state.data.noSector, O.fromNullable, O.getOrElse(() => false))}
      />
      {falseOrEmpty(<NoFocusIndicator
        state={props.state}
        setState={props.setState}
        focusType="sector"
        noFocusLens={invProLens("noSector")}
        extraFieldLens={invProLens("sectorIds")}
      />)(isInstitutional)}
    </DividerSection>
    <ButtonsContainer klasses={"mt-2"} divider>
      <ButtonSubmit
        text={"Save Profile"}
        loading={props.state.loading}
        onClick={constVoid}
        loadingText={"Saving"}
        disabled={(props.state.data.focusIds?.length === 0 && !props.state.data.noFocus) || (props.state.data.sectorIds?.length === 0 && !props.state.data.noSector)}
      />
      {mapOrEmpty((dismissButton: ReactElement) => dismissButton)(props.dismissButton)}
    </ButtonsContainer>
  </Form>;
};
