import { type Dispatch, type SetStateAction } from "react";
import { constVoid, pipe } from "fp-ts/lib/function";

import type { CommonOrganizations } from "@scripts/api/commonOrganizations";
import { makeDeepLinkModalUrl } from "@scripts/codecs/deepLinkTarget";
import { E, O } from "@scripts/fp-ts";
import { onboardingModalId } from "@scripts/generated/domaintables/deepLinkTypes";
import { investorInstitutional } from "@scripts/generated/domaintables/userPersonas";
import type { ApiRedirect } from "@scripts/generated/models/apiRedirect";
import type { InstitutionalInvestorInfoPostC } from "@scripts/generated/models/institutionalInvestorInfo";
import type { SignupPostC } from "@scripts/generated/models/signup";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { InvestorInstitutionForm } from "@scripts/react/actions/forms/investorInstitution/InvestorInstitutionForm";
import { ButtonSecondary, ButtonSubmit } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Form } from "@scripts/react/components/form/Form";
import { OrganizationAutocomplete } from "@scripts/react/components/form/OrganizationAutocomplete";
import { NewPasswordWithConfirmInput } from "@scripts/react/components/form/PasswordInput";
import { Select } from "@scripts/react/components/form/Select";
import { EmailInput, Input } from "@scripts/react/components/form/TextInput";
import { ModalWithDiscard } from "@scripts/react/components/modal/ModalWithDiscard";
import { numberC, optionC, stringC } from "@scripts/react/form/codecs";
import { emptyFormState, type FormState, getValue } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";
import { closeModalFn, useModalStableO } from "@scripts/react/util/useModal";
import { openInSameTab } from "@scripts/routes/router";
import { dashboard } from "@scripts/routes/routing/investorportal/baseRoutes";
import { institutionalInvestorMeta } from "@scripts/routes/routing/issuerportal/dataMeta";
import { personaOptions } from "@scripts/syntax/persona";

import { AcceptTermsCheckbox } from "../AcceptTerms";
import { investorTypeOptions, loginSignUpRedirect, signupFormL } from "./loginPageSyntax";

const onboardingModalUrl = makeDeepLinkModalUrl(dashboard())(onboardingModalId, []);

export const CreateAccountForm = (props: {
  state: FormState<SignupPostC>;
  setState: Dispatch<SetStateAction<FormState<SignupPostC>>>;
  organizations: CommonOrganizations;
  redirect: O.Option<string>;
}) => {
  const [invProfileModalOpen, apiRedirect, openInvProfileModal] =
    useModalStableO<ApiRedirect & { personaId: O.Option<number> }>("Investor Profile Modal");

  const initialState = emptyFormState<InstitutionalInvestorInfoPostC>({ investorTypeId: props.state.data.investorTypeId });

  return <>
    <Form
      headers={O.none}
      onFailure={O.none}
      onSuccess={(fs, resp) => pipe(
        props.redirect,
        O.fold(
          () => openInSameTab(onboardingModalUrl),
          () => pipe(
            fs.data.personaId,
            O.fromNullable,
            O.filter(id => investorInstitutional.id === id),
            O.fold(
              () => loginSignUpRedirect(resp.data.redirect),
              (personaId) => openInvProfileModal({
                ...resp.data,
                personaId: O.some(personaId),
              })
            )
          )
        )
      )}
      state={props.state}
      setState={props.setState}
      url={V2Router.baseAuthControllerSignupPost()}
    >
      <Input
        autoComplete="given-name"
        codec={stringC}
        labelOrAriaLabel={E.left("First Name")}
        lens={signupFormL("firstName")}
        placeholder={O.none}
        state={props.state}
        setState={props.setState}
        type={"text"}
      />
      <Input
        autoComplete="family-name"
        codec={stringC}
        labelOrAriaLabel={E.left("Last Name")}
        lens={signupFormL("lastName")}
        placeholder={O.none}
        state={props.state}
        setState={props.setState}
        type={"text"}
      />
      <EmailInput
        autoComplete="username"
        codec={stringC}
        labelOrAriaLabel={E.left("Email address")}
        lens={signupFormL("email")}
        state={props.state}
        setState={props.setState}
      />
      <OrganizationAutocomplete
        codec={stringC}
        lens={signupFormL("company")}
        organizations={props.organizations}
        placeholder={O.none}
        setState={props.setState}
        state={props.state}
      />
      <Select
        codec={numberC}
        isSearchable={false}
        labelOrAriaLabel={E.left("Which term best describes you")}
        lens={signupFormL("personaId")}
        linked={signupFormL("investorTypeId")}
        options={personaOptions}
        placeholder={O.some("Select")}
        state={props.state}
        setState={props.setState}
      />
      {pipe(
        getValue(props.state, signupFormL("personaId")),
        O.filter(personaId => investorInstitutional.id === personaId),
        mapOrEmpty(() =>
          <Select
            state={props.state}
            setState={props.setState}
            lens={signupFormL("investorTypeId")}
            codec={optionC(numberC)}
            options={investorTypeOptions}
            labelOrAriaLabel={E.left("Institutional Investor Type")}
            isSearchable={false}
            isClearable={false}
            placeholder={O.none}
            requiredOverride={true}
          />
        )
      )}
      <NewPasswordWithConfirmInput
        codec={stringC}
        confirmProps={O.some({
          labelOrAriaLabel: E.left("Confirm Password"),
          lens: signupFormL("passwordConfirm"),
        })}
        labelOrAriaLabel={E.left("Password")}
        lens={signupFormL("password")}
        placeholder={O.none}
        state={props.state}
        setState={props.setState}
      />
      <AcceptTermsCheckbox
        formState={props.state}
        setFormState={props.setState}
        lens={signupFormL("termsAccepted")}
      />
      <div {...klass("form-footer-container")}>
        <ButtonSubmit
          {...klass("w-100")}
          loading={props.state.loading}
          loadingText={"Creating Account"}
          onClick={constVoid}
          text={"Create Account"}
        />
      </div>
    </Form>
    {mapOrEmpty((_: ApiRedirect & { personaId: O.Option<number> }) => (
      <ModalWithDiscard
        type="primary"
        escapable={false}
        size={"modal-lg"}
        id={institutionalInvestorMeta.type}
        title={"Your Investor Profile"}
        icon={O.none}
        initialState={initialState}
        dismissAction={closeModalFn(() => loginSignUpRedirect(_.redirect))}
        open={invProfileModalOpen}
      >
        {({ state, setState }, handleDismiss) => <InvestorInstitutionForm
          state={state}
          setState={setState}
          onSuccess={() => loginSignUpRedirect(_.redirect)}
          dismissButton={O.some(
            <ButtonSecondary onClick={handleDismiss}>
              Skip for now
            </ButtonSecondary>
          )}
          personaId={_.personaId}
          showInvestorType={true}
        />}
      </ModalWithDiscard>
    ))(apiRedirect)}
  </>;
};
